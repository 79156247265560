import * as React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
//import {useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layouts/gated-layout";
import Advertisement from "../../components/layouts/advertisement-column";
import Logo from "../../images/asterra-logo-color.svg";
import { PrimaryButton } from "../../components/buttons/buttons";
import { useTranslation, Trans } from "react-i18next";

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const PasswordColumn = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .logo {
    position: absolute;
    inset: 3rem auto auto 3rem;
  }
  .password {
    padding: 3rem;
    max-width: 75%;
    width: 75%;
    font-size: 0.75rem;
    h1 {
      margin: 0 0 0.75rem 0;
    }
    p {
      margin: 0 0 1.5rem 0;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    .password {
      max-width: 100%;
      width: 100%;
    }
  }
`;

const Password = ({ location }) => {
  // Set email address to send reset email to
  const [userEmail, setUserEmail] = React.useState("");
  React.useEffect(() => {
    if (location.state !== null) {
      setUserEmail(location.state.email);
    }
  }, [location.state, location.email]);

  // Translation component
  const { t } = useTranslation();

  return (
    <Layout>
      <Container>
        <PasswordColumn>
          <Link to="/">
            <img
              src={Logo}
              className="img-fluid logo"
              alt="ASTERRA full color logo"
            />
          </Link>
          <div className="password">
            <h1>{t("reset.h1")}</h1>
            <p>
              {t("reset.description")} {`${userEmail}`}
            </p>
            <PrimaryButton link={"/"} title={t("reset.cta")} />
          </div>
        </PasswordColumn>
        <Advertisement />
      </Container>
    </Layout>
  );
};
export default Password;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
