import React from "react";
import styled from "styled-components";
import { QuaternaryButton } from "../buttons/buttons";
import Cityscape from "../../images/pages/login/cityscape.jpg";
import Waves from "../../images/pages/login/waves.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";
// import { Link } from "gatsby";

const Column = styled.section`
  background: url(${Cityscape}) center / cover no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  .advertisement {
    padding: 3rem;
    margin: 3rem;
    max-width: 75%;
    text-align: center;
    background: ${(props) => props.theme.colors.primaryColors.gradient};
    z-index: 1;
    font-size: 0.75rem;
    min-width: 650px;
    h1 {
      min-width: 400px;
      font-size: 3.25rem;
    }
    h2 {
      min-width: 400px;
      font-size: 2.25rem;
    }
    h4 {
      margin: 4vw 0;
      min-width: 500px;
      max-width: 550px;
      font-size: 1.5rem;
    }
  }
  .waves {
    position: absolute;
    inset: auto 0 0 auto;
    max-width: 80%;
  }
  .question {
    position: absolute;
    inset: auto auto 3rem 3rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    display: none;
  }
`;

const style = {
  unorderedList: {
    listStyleType: "disc",
    marginLeft: 30,
    marginBottom: 30,
  },
  listItem: {
    marginBottom: 5,
    fontSize: 13,
  },
};

const Advertisement = () => {
  const AdListArr = [1, 2, 3];

  // Translation component
  const { t } = useTranslation("index", { lng: "en" });
  return (
    <Column>
      <div className="advertisement">
        <h1>{t("advertisement.h1")}</h1>
        <h2>{t("advertisement.intro")}</h2>
        <h4>{t("advertisement.content")}</h4>
        <QuaternaryButton
          title={t("advertisement.cta")}
          link={"https://asterra.io/about/contact-us/"}
          target={"_blank"}
        />
      </div>
      <img
        src={Waves}
        className="img-fluid waves"
        alt={t("advertisement.wave-image.alt")}
      />
      {/* Remove link to FAQ page for now */}
      {/* <div className="question">
        <p className="text-glow">
          <strong>Have a question?</strong> Check out our{" "}
          <Link to="/faq" className="standard-text-link">
            FAQ page.
          </Link>
        </p>
      </div> */}
    </Column>
  );
};

export default Advertisement;
