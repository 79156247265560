import React, { createContext } from "react";

// Replace with your own data
import Data from "./data";

const DataContext = createContext(Data);
const ContextProvider = (props) => {
  return (
    <DataContext.Provider value={Data}>{props.children}</DataContext.Provider>
  );
};

export { DataContext, ContextProvider };
