// import { AuthProvider } from "../../store/authContext";
import React, { useState, useEffect } from "react";
import "../../styles/global.css";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "../../styles/themes";
import { ContextProvider } from "../../data/dataContext";


const Container = styled.div`
  color: ${(props) => props.theme.variables.color};
  position: relative;
  .language {
    position: absolute;
    inset: auto auto 3rem 3rem;
  }
`;

const Layout = ({ children }) => {
  const [theme, setTheme] = useState("dark");
  const setMode = (mode) => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };
  /* eslint-disable no-unused-vars */
  const themeToggler = () => {
    theme === "light" ? setMode("dark") : setMode("light");
  };
  /* eslint-enable no-unused-vars */
  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    localTheme && setTheme(localTheme);
  }, []);

  return (
    <ContextProvider>
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <Container>
        {children}
      </Container>
    </ThemeProvider>
    </ContextProvider>
  );
};
export default Layout;
